#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.button-with-margin {
  margin-right: 10px; /* Adjust the margin as needed */
}

.form-button {
  margin-bottom: 20px;
}

.a-tag{
  color: #333;
}

.list-table {
  margin-top: 24px;
}
.list-table .ant-table {
  border-radius: 2px;
  background: #fff;
}
.list-table .ant-table thead.ant-table-thead .ant-table-cell {
  background: #fff;
  box-shadow: inset 0px -2px 0px rgb(255 255 255 / 4%);
  padding: 16px 30px;
  height: auto;
  /* border-bottom: 1px solid #f2f2f2; */
}
.list-table .ant-table thead.ant-table-thead .ant-table-cell span.ant-table-column-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #000;
  flex: none;
  width: auto;
}
.list-table .ant-table thead.ant-table-thead .ant-table-cell .ant-table-column-sorters {
  justify-content: flex-start;
  gap: 17px;
}
.list-table .ant-table thead.ant-table-thead .ant-table-cell::before {
  display: none;
}
.list-table span.ant-table-column-sorter.ant-table-column-sorter-full {
  color: rgb(255 255 255 / 30%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}
.list-table span.ant-table-column-sorter.ant-table-column-sorter-full span.anticon.active {
  color: #94c950;
}
.list-table span.ant-table-column-sorter.ant-table-column-sorter-full span {
  color: #999;
}
.list-table .ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
  color: #aaa;
}
.list-table .ant-table tbody.ant-table-tbody .ant-table-cell {
  background: #fff;
  padding: 8px 30px;
  height: auto;
  border: none !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  border-top: 1px solid #f0f0f0 !important;
}
.list-table .ant-table tbody.ant-table-tbody .ant-table-cell p {
  margin: 0;
}
.list-table .ant-table tbody.ant-table-tbody tr:hover .ant-table-cell {
  background: #f9f9f9;
  border-radius: 0 !important;
}
p.pending {
  color: #aaa;
}
p.active {
  color: #36A933;
}
p.blocked {
  color: #FF2020;
}
button.ant-btn.action-btn:hover {
  border-color: #8cc63f;
  position: relative;
  z-index: 1;
  background: #f0f0f0;
  border-color: rgb(170 170 170 / 50%);
}

/* Delete modal css */
.ant-modal-confirm-confirm.delete-modal .ant-modal-content {
  background: #fff;
  border: 1px solid #AAAAAA;
  border-radius: 16px;
  padding: 20px 0 10px 0px;
  text-align: center;
}
.ant-modal-confirm-confirm.delete-modal {
  padding-bottom: 0;
  max-width: 95%;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-content .ant-modal-confirm-body {
  display: block;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-content span.anticon.anticon-exclamation-circle {
  display: none;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-content .ant-modal-confirm-body span.ant-modal-confirm-title {
  display: block;
  margin-bottom: 25px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #555;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-content .ant-modal-confirm-body .ant-modal-confirm-content {
  margin: 0;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-confirm-btns .ant-btn-primary:hover {
  background: linear-gradient(-269.36deg, #c1da9f -23.62%, #8cc63f 120.63%);
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-confirm-btns .ant-btn-primary {
  margin-left: 0;
  margin-right: 18px;
  background: linear-gradient(269.36deg, #c1da9f  -23.62%, #8cc63f 120.63%);
  padding: 7px 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #FFFFFF;
  height: auto;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  transition: none;
  border: none;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-confirm-btns .ant-btn-default {
  margin-left: 0;
  margin-right: 0px;
  background: transparent;
  padding: 7px 21px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #aaa;
  height: auto;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  transition: none;
  border: none;
}
.ant-modal-confirm-confirm.delete-modal .ant-modal-confirm-btns .ant-btn-default:hover {
  color: #8cc63f;
}
.ant-modal-confirm-confirm.delete-modal button.ant-modal-close {
  color: #aaa;
}
.ant-modal-confirm-confirm.delete-modal button.ant-modal-close:hover {
  color: #777;
}
.ant-modal-confirm-confirm.delete-modal button.ant-modal-close {
  color: #aaa;
  inset-inline-end: 5px;
  outline: none;
  box-shadow: none;
  margin-top: -10px;
}
.pagecontent-header-rightbox.employer-header-content {
  gap: 24px;
}
.ant-select.status-select {
  margin: 0 30px 0 3px;
}
.pagecontent-header-rightbox.employer-header-content .ant-select span.ant-select-arrow {
  color: #aaa;
  top: 13px;
  right: 0;
}
.pagecontent-header-rightbox.employer-header-content .ant-select .ant-select-selector {
  background: transparent;
  border: none;
  box-shadow: none !important;
  outline: none;
  padding: 0;
  height: auto;
}
.pagecontent-header-rightbox.employer-header-content .ant-select .ant-select-selector span.ant-select-selection-placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #AAAAAA;
  padding-right: 20px;
}
.pagecontent-header-rightbox.employer-header-content .ant-select .ant-select-selector span.ant-select-selection-item {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #AAAAAA;
  padding-right: 20px;
}

.file-box span.anticon {
  font-size: 17px;
}
.file-box {
  display: flex;
  align-items: center;
  gap: 12px;
}
.form-btns button.ant-btn.self-btn {
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  padding: 7px 8px;
  text-align: center;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #777 !important;
  height: auto;
  transition: none;
  letter-spacing: 0.4px;
  outline: none;
}
.form-btns button.ant-btn.self-btn:hover {
  color: #8cc63f !important;
}
.form-btns button.ant-btn.common-btn {
  padding: 7px 30px;
}
.form-btns {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.ant-modal-body form.ant-form.common-form {
  margin-top: 25px;
  margin-bottom: 0px;
}
.ant-modal-body form.ant-form.common-form input {
  padding: 8px 16px;
}
.ant-modal-body span.ant-upload-wrapper {
  margin: 25px 0;
  display: block;
}
.ant-modal-body span.ant-upload-wrapper span.anticon.anticon-inbox {
  color: #8cc63f;
}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #8cc63f;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error {
  color: #000;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
  color: #000;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  padding: 3px 10px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-actions .anticon, .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-actions .anticon:hover {
  color: #000;
}
.ant-upload-list-item.ant-upload-list-item-error+div {
  display: none;
}
@media (max-width: 1300px) {
  .ant-select.status-select {
    margin: 0 10px 0 3px;
  }
}
@media (max-width: 1200px) {
  .pagecontent-header-rightbox.employer-header-content {
      gap: 16px;
  }    
}
@media (max-width: 991px) {
  .list-table .ant-table thead.ant-table-thead .ant-table-cell {
    padding: 13px 15px;
  }
  .list-table .ant-table tbody.ant-table-tbody .ant-table-cell {
    padding: 8px 15px;
  }
  .ant-modal-confirm-confirm.delete-modal .ant-modal-content {
    padding: 70px 30px 60px;
  }
}
@media (max-width: 767px) {
  .list-table {
    margin-top: 15px;
  }
  .list-table .ant-table thead.ant-table-thead .ant-table-cell span.ant-table-column-title {
    font-size: 12px;
    line-height: 18px;
  }
  .list-table .ant-table tbody.ant-table-tbody .ant-table-cell {
    font-size: 12px;
    line-height: 20px;
  }
  button.ant-btn.action-btn {
    width: 30px;
    height: 35px;
  }
  button.ant-btn.action-btn img {
    height: 13px;
  }
  .ant-modal-confirm-confirm.delete-modal .ant-modal-content .ant-modal-confirm-body span.ant-modal-confirm-title {
    font-size: 15px;
    line-height: 30px;
  }
  .ant-upload-wrapper .ant-upload-drag p.ant-upload-text {
    font-size: 12px;
  }
}

.status {
  opacity: 1.0;
}

.status.not-completed {
  opacity: 0.65;
}

.status.completed {
  color: green;
}

.status.uploaded,
.status.processing {
  color: #c59d05;
  /* Optional: Add text-shadow or background as needed */
}

.status.failed {
  color: red;
}