/* --------------------------------------------------------------------------------------- */
/* Loader
/* --------------------------------------------------------------------------------------- */

.o-page-loader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: rgb(0 0 0 / 12%);
    z-index: 99999;
    transition: opacity 0.5s, clip-path 1s;
    backdrop-filter: blur(12px);
}
  
.o-page-loader--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
  
.o-page-loader--spinner {
    width: 60px;
    height: 60px;
    background: linear-gradient(269.36deg, #8cc63f -23.62%, #fff 120.63%);
    margin: 20px auto;
    animation: rotate-plane 1.5s infinite ease-in-out;
    -webkit-animation: rotate-plane 1.5s infinite ease-in-out;
}
  
.o-page-loader--message {
    color: #8cc63f;
    font-weight: bold;
    font-size: 18px;
}
  
  /* --------------------------------------------------------------------------- */
  /* Animation - Loading Spinner
  /* --------------------------------------------------------------------------- */

@-webkit-keyframes rotate-plane {
    0% {
        -webkit-transform: perspective(120px);
        transform: perspective(120px);
    }
  
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
  
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }
  
  @keyframes rotate-plane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
  
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
  
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  