.App {
    font-family: sans-serif;
    text-align: center;
}

html, body, #root, .App, #mapId {
    width: 100%;
    height: 100%;
    margin: 0;
    padding:0;
}

.checkbox-container {
    color: #fff;
    position: absolute; /* Position the checkbox container absolutely */
    top: 80px; /* Adjust this value to move the checkboxes down */
    left: 10px; /* Adjust this value to move the checkboxes from the left edge */
    background: rgba(50, 50, 50, 0.92); /* Optional: Add a background for better readability */
    padding: 10px;
    border-radius: 5px; /* Optional: Add some border radius for a nicer look */
    z-index: 1000; /* Ensure the container is above the map controls */
    max-height: calc(100% - 100px); /* Adjust this value to set the maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
}